@import '../../variables';

.Loaders {
  &__container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    justify-items: center;
    align-items: center;
  }
}

.Logo {
  width: 100%;
  margin: 0;
  text-align: center;
  height: 32px;
}
